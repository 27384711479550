import { ArticleJsonLd } from 'next-seo'
import { useRouter } from 'next/router'
import { useCallback, useEffect, useRef, useState } from 'react'
import { ISbStoryData } from 'storyblok-js-client'
import styled from 'styled-components'

import { ButtonLink, Container, Tag, Text } from 'common/UI'
import { SITE_ORIGIN } from 'common/constants'
import { BlogPostStoryblok } from 'common/types'
import { getStoryblokCacheValue } from 'common/utils/content'
import { formatDateLocale } from 'common/utils/datetime'
import { removeTrailingSlash } from 'common/utils/string'
import { useDataContext } from 'lib/dataContext'
import { useTranslation } from 'lib/i18n'
import { Storyblok } from 'lib/storyblok'
import { Empty } from 'modules/AllEntries/Empty'
import { Editable } from 'modules/Blocks/Editable'
import { Layout } from 'modules/Layout/Layout'
import { BlogThumb, CMSImage, CMSRichText, Loader } from 'modules/shared'
import { Seo } from 'modules/shared/Seo'
import { SocialShare } from 'modules/shared/SocialShare'

type Props = {
  story: ISbStoryData<BlogPostStoryblok>
}

export const BlogPostPage = ({ story }: Props): JSX.Element => {
  const { i18n, locale } = useTranslation()
  const sideBarRef = useRef<HTMLDivElement>(null)
  const [sideBarHeight, setSidebarHeight] = useState<number>(0)
  const { config } = useDataContext()
  const {
    query: { lang },
    isPreview,
  } = useRouter()

  const [loading, setLoading] = useState(true)
  const [data, setData] = useState<ISbStoryData<BlogPostStoryblok>[] | null>(
    null
  )
  const [error, setError] = useState(false)

  const fetch = useCallback(async () => {
    setLoading(true)
    setError(false)

    try {
      const {
        data: { stories },
      } = await Storyblok.get('cdn/stories', {
        page: 1,
        per_page: 3,
        version: isPreview ? 'draft' : 'published',
        starts_with: `${lang}/`,
        sort_by: 'first_published_at:desc',
        content_type: 'blog-post',
        resolve_relations: 'blog-post.services',
        cv: getStoryblokCacheValue(isPreview),
        excluding_ids: story.id.toString(),
      })

      setData(stories)
    } catch {
      setError(true)
    }

    setLoading(false)
  }, [isPreview, story])

  useEffect(() => {
    fetch()
  }, [fetch])

  useEffect(function handleSidebarHeight() {
    const onResize = () => {
      if (sideBarRef?.current?.clientHeight) {
        setSidebarHeight(sideBarRef?.current?.clientHeight)
      }
    }

    onResize()
    window.addEventListener('resize', onResize)

    return () => window.removeEventListener('resize', onResize)
  }, [])

  return (
    <Layout>
      <Seo story={story} />
      <ArticleJsonLd
        title={
          story.content?.seo?.title
            ? JSON.stringify(story.content.seo.title).slice(1, -1)
            : ''
        }
        authorName="TFP"
        publisherName="TFP"
        publisherLogo={config?.content.menu_logo?.filename || ''}
        description={
          story.content?.seo?.description
            ? JSON.stringify(story.content.seo.description).slice(1, -1)
            : ''
        }
        datePublished={story.created_at}
        url={
          story.content?.canonical_url ||
          removeTrailingSlash(`${SITE_ORIGIN}${story.full_slug}`)
        }
        images={
          story.content?.seo?.og_image || story.content?.seo?.twitter_image
            ? [
                // we're not adding both because probably they will be the same and it's not
                // very professional to have two very similar images
                (story.content?.seo?.og_image ||
                  story.content?.seo?.twitter_image) as string,
              ]
            : []
        }
      />
      <Wrapper css={{ paddingBottom: '5rem' }}>
        {story.content.cover_image && (
          <StyledCMSImage
            image={story.content.cover_image}
            objectposition={story.content.object_position}
          />
        )}
        <InnerWrapper>
          <Sidebar ref={sideBarRef}>
            <Container>
              <WrapperSocialShare>
                <Text as="span" variant="sixteen">
                  {i18n('share')}:
                </Text>
                <SocialShare css={{ marginTop: '0.75rem' }} />
              </WrapperSocialShare>
            </Container>
          </Sidebar>
          <Header sidebarHeight={sideBarHeight || 0}>
            <Tags>
              {story.content.services?.name && (
                <Tag
                  label={story.content.services.name}
                  css={{ marginRight: '.75rem' }}
                />
              )}
              {story.first_published_at && (
                <Text as="span" variant="fourteen">
                  {formatDateLocale(story.first_published_at, locale)}
                </Text>
              )}
            </Tags>
            <Text css={{ marginBottom: '2rem' }} as="h1" variant="title">
              {story.content.title}
            </Text>
            <SocialShare />
          </Header>
          <ContentHolder>
            <Editable block={story.content}>
              <CMSRichText
                richtext={story.content.content}
                renderer={(type, renderDefault) => {
                  if (type === 'blok') {
                    return renderDefault()
                  } else if (type === 'blockquote') {
                    return (
                      <Container variant="narrow">{renderDefault()}</Container>
                    )
                  } else {
                    return (
                      <Container variant="typography">
                        {renderDefault()}
                      </Container>
                    )
                  }
                }}
              />
            </Editable>
            <Container css={{ marginTop: '1.25rem' }} variant="typography">
              <Text as="span" variant="sixteen">
                {i18n('likeTheArticle')}
              </Text>
              <SocialShare css={{ marginTop: '0.75rem' }} />
            </Container>
          </ContentHolder>
        </InnerWrapper>
      </Wrapper>
      <Container>
        {loading && !data?.length && <Loader />}
        {data?.length ? (
          <WrapperTumbs>
            <WrapperSection>
              <Tag label={i18n('blogPostsSectionTag')} />
              <WrapperSectionTitle>
                <Text as="h3" variant="title/large">
                  {i18n('blogPostsSectionTitle')}
                </Text>
                <ButtonLink
                  css={{ width: 'fit-content' }}
                  variant="ghost"
                  href={`/${lang}/blog`}
                >
                  {i18n('blogPostsSectionSeeAll')}
                </ButtonLink>
              </WrapperSectionTitle>
            </WrapperSection>
            <BlogPostsGrid>
              {data?.map((item) => (
                <BlogThumb item={item} key={item.id} />
              ))}
            </BlogPostsGrid>
          </WrapperTumbs>
        ) : (
          <Empty css={{ width: '100%' }} isError={error} />
        )}
      </Container>
    </Layout>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const InnerWrapper = styled.div`
  margin-top: 2.5rem;
  width: 100%;

  ${({ theme }) => theme.media.xl} {
    margin-top: 3.75rem;
  }
`

const WrapperSocialShare = styled.div`
  max-width: fit-content;
  background-color: ${({ theme }) => theme.colors.palette.white};
  padding: 0.75rem 1rem;
  border-radius: 0.75rem;
  box-shadow: ${({ theme }) => theme.shadows.small};
`

const Tags = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 1.25rem;
`

const StyledCMSImage = styled(CMSImage)`
  width: 100%;
  max-width: 105rem;
  margin: 0 auto;
  max-height: 30rem;
  object-fit: cover;
`

const Header = styled.div<{ sidebarHeight: number }>`
  display: flex;
  flex-direction: column;
  align-items: center;

  max-width: 49rem;
  margin: 0 auto;
  margin-bottom: 2.5rem;
  padding: 0rem 1.25rem;

  text-align: center;

  margin-top: 0;

  ${({ theme }) => theme.media.xl} {
    margin-bottom: 3.75rem;
    margin-top: ${({ sidebarHeight }) => `-${sidebarHeight}px`};
  }
`

const ContentHolder = styled.div`
  position: relative;
  z-index: ${({ theme }) => theme.zIndex.mid};
`

const Sidebar = styled.div`
  margin-top: 1rem;
  position: sticky;
  top: 5rem;
  display: none;

  z-index: ${({ theme }) => theme.zIndex.high};

  ${({ theme }) => theme.media.xl} {
    display: block;
    margin-top: 0rem;
  }
`

export const WrapperTumbs = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3rem;
  z-index: 2;
  width: 100%;
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;

  ${({ theme }) => theme.media.md} {
    padding-bottom: 3.75rem;
  }
`

const BlogPostsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  column-gap: 1.25rem;
  row-gap: 3rem;
  z-index: 2;

  ${({ theme }) => theme.media.md} {
    grid-template-columns: repeat(2, 1fr);
  }

  ${({ theme }) => theme.media.lg} {
    grid-template-columns: repeat(3, 1fr);
  }
`

const WrapperSection = styled.div`
  display: flex;
  flex-direction: column;
`

const WrapperSectionTitle = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: space-between;
  margin-top: 1rem;

  ${({ theme }) => theme.media.lg} {
    flex-direction: row;
  }
`
