import dayjs, { extend as dayJsExtend } from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import { ISbStoryData } from 'storyblok-js-client'
import styled from 'styled-components'

import { ColorTag, Icon, Text, ButtonLink } from 'common/UI'
import { OpenEveningStoryblok } from 'common/types'
import { GTMEvents, trackEvent } from 'common/utils/gtm'
import {
  removeLeadingAndTrailingSlash,
  toCapitalize,
} from 'common/utils/string'
import { addAlphaToColor } from 'common/utils/style'
import { useTranslation } from 'lib/i18n'

dayJsExtend(customParseFormat)

type Props = {
  item: ISbStoryData<OpenEveningStoryblok>
  variant?: 'big' | 'small'
}

export const OpenEveningThumb = ({
  item,
  variant = 'small',
  ...props
}: Props): JSX.Element => {
  const { i18n, locale } = useTranslation()

  const handleTrackEvent = () => {
    trackEvent(GTMEvents.OpenEvening, {
      'tfp-title': item.content.title || 'n/a',
      'tfp-location': item.content.location || 'n/a',
      'tfp-date-time': item.content.date_time || 'n/a',
      'tfp-type': item.content.type,
    })
  }

  const isExternal = item?.content?.link?.linktype === 'url'

  const dayJsDate = dayjs(
    `${item.content.date_time}+00:00`,
    'YYYY-MM-DD HH:mmZ'
  )
  const day = dayJsDate.locale(locale).format('dddd')
  const date = dayJsDate.locale(locale).format('ll')
  const time = dayJsDate.locale(locale).format('HH:mm')
  const isInLessThan7Days = dayJsDate.subtract(7, 'days').isBefore(new Date())

  const types = {
    clinic: i18n('eveningType.clinic'),
    online: i18n('eveningType.online'),
    other: i18n('eveningType.other'),
  }

  return (
    <Item {...props}>
      {item.content.type === 'clinic' && <Dots />}

      <ContentWrapper style={{ flex: 1 }} variant={variant}>
        <Content>
          <ColorTag
            color={isInLessThan7Days ? 'blue' : 'foreground'}
            label={toCapitalize(item.content.type)}
            icon={item.content.type === 'online' ? 'world' : 'map-pointer'}
          />

          <Meta>
            <Text as="h2" variant="twenty/regular">
              {item.content?.title || item?.name}
            </Text>
          </Meta>

          <FullWidth>
            {item.content.date_time && (
              <div>
                <Meta description css={{ marginBottom: '.375rem' }}>
                  <Icon
                    icon="calendar"
                    css={{ flexShrink: 0, marginRight: '0.5rem' }}
                  />
                  <Text as="span" variant="eighteen">
                    {day.slice(0, 3)}, {date}
                  </Text>
                  <Dot />
                  <Text as="span" variant="eighteen">
                    {time}
                  </Text>
                </Meta>
              </div>
            )}
            {item.content.location ? (
              <Meta description>
                <Icon
                  icon={
                    item.content.type === 'online' ? 'world' : 'map-pointer'
                  }
                  css={{ flexShrink: 0, marginRight: '0.5rem' }}
                />
                <Text as="span" variant="eighteen">
                  {item.content.location}
                  {` (${types[item?.content?.type]})`}
                </Text>
              </Meta>
            ) : (
              <Meta description>
                <Icon
                  icon="map-pointer"
                  css={{ flexShrink: 0, marginRight: '0.5rem' }}
                />
                <Text as="span" variant="eighteen">
                  {types[item.content.type]}
                </Text>
              </Meta>
            )}
          </FullWidth>
        </Content>

        <ButtonLink
          variant="solid"
          size="small"
          isFull
          css={{ marginTop: 'auto', flexGrow: 0, width: 'fit-content' }}
          onClick={handleTrackEvent}
          {...(isExternal
            ? {
                leftIcon: 'external',
                target: '_blank',
                rel: 'noopener noreferrer',
                href: item?.content?.link?.cached_url,
              }
            : {
                rightIcon: 'arrow-right',
                href: `/${removeLeadingAndTrailingSlash(
                  item?.content?.link?.cached_url || item.full_slug
                )}`,
              })}
        >
          {i18n('eventSignUp')}
        </ButtonLink>
      </ContentWrapper>
    </Item>
  )
}

const Item = styled.li<{ isDrawer?: boolean }>`
  display: flex;
  flex-direction: column;
  position: relative;

  color: inherit;
  background-color: ${({ theme }) => theme.colors.background.dark};

  border-radius: 1.25rem;
  overflow: hidden;

  min-height: 10rem;

  ${({ theme }) => theme.media.md} {
    flex-direction: row;
    min-width: ${({ isDrawer }) => isDrawer && '25.8125rem'};
  }
`

const Dots = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  width: 6.8125rem;
  height: 100%;

  background-image: url('/assets/vertical-dots.png');
  background-size: cover;
  background-position: left center;
  background-repeat: no-repeat;
`

const Meta = styled.div<{ description?: boolean }>`
  display: flex;
  align-items: center;
  color: ${({ theme, description }) =>
    description
      ? addAlphaToColor(theme.colors.foreground.default, 80)
      : theme.colors.foreground.default};
`

const FullWidth = styled.div`
  width: 100%;
`

const ContentWrapper = styled.div<{ variant?: Props['variant'] }>`
  padding: 1rem;

  display: flex;
  flex-direction: column;
  gap: 24px;

  ${({ theme }) => theme.media.md} {
    max-width: ${({ variant }) => (variant === 'big' ? '19rem' : 'unset')};
  }
`

const Content = styled.div<{ variant?: Props['variant'] }>`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;

  flex-shrink: 0;
`

const Dot = styled.div`
  display: flex;
  width: 0.125rem;
  height: 0.125rem;
  background: ${({ theme }) =>
    addAlphaToColor(theme.colors.foreground.default, 80)};
  margin: 0px 0.25rem;
`
