import styled, { ThemeProvider } from 'styled-components'

import {
  darkBlueTheme,
  lightTheme,
  lightOrangeTheme,
  lightGreyTheme,
  darkPurpleTheme,
  theme as fullTheme,
} from 'common/theme'

type Props = {
  colorTheme?: string | number
  children: React.ReactNode
}

export const Theme: React.FC<Props> = ({
  colorTheme = 'light',
  children,
  ...props
}) => {
  const themes: Record<string, typeof lightTheme> = {
    light: lightTheme,
    'dark-blue': darkBlueTheme,
    'light-orange': lightOrangeTheme,
    'light-grey': lightGreyTheme,
    'dark-purple': darkPurpleTheme,
  }

  return (
    <ThemeProvider theme={{ ...fullTheme, colors: themes[colorTheme] }}>
      <Wrapper {...props}>{children}</Wrapper>
    </ThemeProvider>
  )
}

const Wrapper = styled.div`
  position: relative;
  background-color: ${({ theme }) => theme.colors.background.default};
  color: ${({ theme }) => theme.colors.foreground.default};
`
