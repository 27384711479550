import NextImage from 'next/image'
import NextLink from 'next/link'
import { ISbStoryData } from 'storyblok-js-client'
import styled from 'styled-components'

import { Tag, Text } from 'common/UI'
import { BlogPostStoryblok, PatientStoryStoryblok } from 'common/types'
import { getImageAttributes } from 'common/utils/content'
import { formatDateLocale } from 'common/utils/datetime'
import { removeTrailingSlash } from 'common/utils/string'
import { useTranslation } from 'lib/i18n'

type Props = {
  item: ISbStoryData<BlogPostStoryblok> | ISbStoryData<PatientStoryStoryblok>
  single_column?: boolean
}

export const BlogThumb = ({ item, ...props }: Props): JSX.Element => {
  const { locale } = useTranslation()

  const serviceColors = () => {
    switch (item.content.services?.content?.type) {
      case 'diagnosis':
        return 'palette.yellow.default'
      case 'treatment':
        return 'palette.pink.default'
      case 'donation':
        return 'palette.orange.default'
      case 'info':
        return 'palette.hyacinth.default'
      case 'preservation':
        return 'palette.aqua.dark'
      case 'condition':
        return 'palette.violet.wild'
      default:
        return 'palette.blue.200'
    }
  }

  return (
    <Thumb href={removeTrailingSlash(`/${item.full_slug}`)} {...props}>
      {item.content.cover_image && item.content.cover_image.filename && (
        <ImageHolder>
          <NextImage
            src={
              getImageAttributes(
                item.content.cover_image,
                '800x400/smart/filters:format(webp)'
              ).src
            }
            alt={getImageAttributes(item.content.cover_image).alt}
            fill
            style={{ objectFit: 'cover', height: '100%' }}
          />
        </ImageHolder>
      )}
      <Content>
        <Meta>
          {item.content.services?.content?.type && (
            // for some reason css is not working on tagV2 in this file but does owrk on blobbigthumb which is confusion, using a div for now
            <div css={{ textTransform: 'capitalize', marginRight: '0.75rem' }}>
              <Tag
                label={item.content.services?.content?.type}
                color={serviceColors()}
              />
            </div>
          )}

          <Text as="span" variant="sixteen">
            {item.first_published_at
              ? formatDateLocale(item.first_published_at, locale)
              : ''}
          </Text>
        </Meta>

        <Text
          as="h2"
          variant="title/small/regular"
          css={{ marginTop: '0.75rem' }}
        >
          {item.content.title}
        </Text>
      </Content>
    </Thumb>
  )
}

export const Thumb = styled(NextLink)`
  color: inherit;
`

const ImageHolder = styled.div`
  position: relative;
  padding-bottom: 63%;
  border-radius: 1rem;
  overflow: hidden;

  ${({ theme }) => theme.media.md} {
    padding-bottom: 75%;
  }
`

const Content = styled.div`
  padding-top: 1.25rem;
`

const Meta = styled.div`
  display: flex;
  align-items: center;
`
