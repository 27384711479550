import styled from 'styled-components'

type ContainerVariant =
  | 'extra-narrow'
  | 'narrow'
  | 'normal'
  | 'typography'
  | 'wide'
  | 'full'

export const Container = styled.div<{ variant?: ContainerVariant }>`
  width: 100%;
  max-width: ${({ variant }) => {
    const variants = {
      typography: '41.875rem',
      'extra-narrow': '36.5rem',
      narrow: '54rem',
      normal: '90rem',
      wide: '96rem',
      full: '100%',
    }

    return variants[variant || 'normal']
  }};

  margin-left: auto;
  margin-right: auto;

  padding-left: 1.25rem;
  padding-right: 1.25rem;

  & & {
    margin: 0;
    padding: 0;
    max-width: none;
  }
`
