const iconsMap = {
  apple: require('./icons/apple.svg'),
  'box-selected': require('./icons/box-selected.svg'),
  'arrow-left': require('./icons/arrow-left.svg'),
  'arrow-right': require('./icons/arrow-right.svg'),
  'double-arrow-left': require('./icons/double-arrow-left.svg'),
  'double-arrow-right': require('./icons/double-arrow-right.svg'),
  booking: require('./icons/booking.svg'),
  box: require('./icons/box.svg'),
  calendar: require('./icons/calendar.svg'),
  checkmark: require('./icons/checkmark.svg'),
  clock: require('./icons/clock.svg'),
  close: require('./icons/close.svg'),
  xclose: require('./icons/xclose.svg'),
  'caret-down': require('./icons/caret-down.svg'),
  'caret-up': require('./icons/caret-up.svg'),
  cookies: require('./icons/cookies.svg'),
  download: require('./icons/download.svg'),
  expand: require('./icons/expand.svg'),
  external: require('./icons/external.svg'),
  face: require('./icons/face.svg'),
  hamburguer: require('./icons/hamburguer.svg'),
  house: require('./icons/house.svg'),
  less: require('./icons/less.svg'),
  loading: require('./icons/loading.svg'),
  mail: require('./icons/mail.svg'),
  'map-pointer': require('./icons/map-pointer.svg'),
  phone: require('./icons/phone.svg'),
  plus: require('./icons/plus.svg'),
  play: require('./icons/play.svg'),
  world: require('./icons/world.svg'),
  search: require('./icons/magnifier.svg'),
  directions: require('./icons/directions.svg'),
  'book-appointment': require('./icons/book-appointment.svg'),
  clinics: require('./icons/clinics.svg'),
  'closure-covid': require('./icons/closure-covid.svg'),
  events: require('./icons/events.svg'),
  'hours-holidays': require('./icons/hours-holidays.svg'),
  'learn-more': require('./icons/learn-more.svg'),
  maps: require('./icons/maps.svg'),
  news: require('./icons/news.svg'),
  treatments: require('./icons/treatments.svg'),
  user: require('./icons/user.svg'),
  dots: require('./icons/dots.svg'),
  filters: require('./icons/filters.svg'),
  info: require('./icons/info.svg'),
  facebook: require('./icons/facebook.svg'),
  twitter: require('./icons/twitter.svg'),
  link: require('./icons/link.svg'),
  'message-bubble': require('./icons/message-bubble.svg'),
  book: require('./icons/book.svg'),
  diagnosis: require('./icons/diagnosis.svg'),
  treatment: require('./icons/treatment.svg'),
  support: require('./icons/support.svg'),
  'usp-book': require('./icons/usp-book.svg'),
  'usp-baby': require('./icons/usp-baby.svg'),
  'usp-heart': require('./icons/usp-heart.svg'),
  'usp-location': require('./icons/usp-location.svg'),
  'usp-university': require('./icons/usp-university.svg'),
  'usp-support': require('./icons/usp-support.svg'),
  'usp-science': require('./icons/usp-science.svg'),
  'usp-star': require('./icons/usp-star.svg'),
  'usp-award': require('./icons/usp-award.svg'),
  'usp-rates': require('./icons/usp-rates.svg'),
  'usp-house': require('./icons/usp-house.svg'),
  'usp-imaging': require('./icons/usp-imaging.svg'),
  'usp-puzzle': require('./icons/usp-puzzle.svg'),
  'usp-genetic': require('./icons/usp-genetic.svg'),
  'usp-treatments': require('./icons/usp-treatments.svg'),
}

export type IconOptions = keyof typeof iconsMap

export function isValidIcon(iconName: unknown): iconName is IconOptions {
  if (typeof iconName !== 'string' || !iconName) return false
  return iconName in iconsMap
}

export const getValidIcon = (icon: unknown): IconOptions | undefined => {
  if (isValidIcon(icon)) {
    return icon
  } else return
}

export type IconProps = React.HTMLProps<HTMLOrSVGImageElement> & {
  icon: IconOptions
}

export const Icon: React.FC<IconProps> = ({ icon, ...props }) => {
  const IconComponent =
    Object.keys(iconsMap).indexOf(icon) !== -1
      ? iconsMap[icon].ReactComponent || iconsMap[icon].default || null
      : null

  return <IconComponent {...props} />
}
