import dynamic from 'next/dynamic'
import { ComponentType } from 'react'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const Components: Record<string, ComponentType<{ block: any }>> = {
  cards: dynamic(() => import('./Cards').then((mod) => mod.Cards)),
  contacts: dynamic(() => import('./Contacts').then((mod) => mod.Contacts)),
  'colored-section': dynamic(() =>
    import('./ColoredSection').then((mod) => mod.ColoredSection)
  ),
  'container-section': dynamic(() =>
    import('./ContainerSection').then((mod) => mod.ContainerSection)
  ),
  conversion: dynamic(() =>
    import('./Conversion').then((mod) => mod.Conversion)
  ),
  'dynamic-form': dynamic(() =>
    import('./DynamicForms').then((mod) => mod.DynamicForm)
  ),
  'dynamic-form-steps': dynamic(() =>
    import('./DynamicForms').then((mod) => mod.DynamicFormSteps)
  ),
  faqs: dynamic(() => import('./FAQS').then((mod) => mod.FAQS)),
  hero: dynamic(() => import('./Heros/Hero').then((mod) => mod.Hero)),
  'dynamic-hero': dynamic(() =>
    import('./Heros/DynamicHero').then((mod) => mod.DynamicHero)
  ),
  'clinic-search-hero': dynamic(() =>
    import('./Heros/ClinicSearchHero').then((mod) => mod.ClinicSearchHero)
  ),
  'info-grid': dynamic(() => import('./InfoGrid').then((mod) => mod.InfoGrid)),
  'rich-text': dynamic(() => import('./RichText').then((mod) => mod.RichText)),
  'section-header': dynamic(() =>
    import('./SectionHeader').then((mod) => mod.SectionHeader)
  ),
  media: dynamic(() => import('./MediaBlock').then((mod) => mod.MediaBlock)),
  'media-carousel': dynamic(() =>
    import('./Carousel').then((mod) => mod.MediaCarousel)
  ),
  testimonial: dynamic(() =>
    import('./Testimonial').then((mod) => mod.Testimonial)
  ),
  'selected-clinics': dynamic(() =>
    import('./SelectedClinics').then((mod) => mod.SelectedClinics)
  ),
  'selected-blog-posts': dynamic(() =>
    import('./SelectedBlogPosts').then((mod) => mod.SelectedBlogPosts)
  ),
  'selected-patient-stories': dynamic(() =>
    import('./SelectedPatientStories').then((mod) => mod.SelectedPatientStories)
  ),
  'text-highlight': dynamic(() =>
    import('./TextHighlight').then((mod) => mod.TextHighlight)
  ),
  timeline: dynamic(() => import('./Timeline').then((mod) => mod.Timeline)),
  'two-columns': dynamic(() =>
    import('./TwoColumns').then((mod) => mod.TwoColumns)
  ),
  'all-entries': dynamic(() =>
    import('./AllEntries').then((mod) => mod.AllEntries)
  ),
  'latest-entries': dynamic(() =>
    import('./LatestEntries').then((mod) => mod.LatestEntries)
  ),
  pricing: dynamic(() => import('./Pricing').then((mod) => mod.Pricing)),
  'success-rates': dynamic(() =>
    import('./SuccessRates').then((mod) => mod.SuccessRates)
  ),
  guestbook: dynamic(() => import('./Guestbook').then((mod) => mod.Guestbook)),
  'column-layout': dynamic(() =>
    import('./ColumnLayout').then((mod) => mod.ColumnLayout)
  ),
  steps: dynamic(() => import('./Steps').then((mod) => mod.Steps)),
  'rich-text-button': dynamic(() =>
    import('./RichTextButton').then((mod) => mod.RichTextButton)
  ),
  'rich-text-banner': dynamic(() =>
    import('./RichTextBanner').then((mod) => mod.RichTextBanner)
  ),
  'iframe-embed': dynamic(() => import('./Iframe').then((mod) => mod.Iframe)),
  'code-embed': dynamic(() =>
    import('./CodeEmbed').then((mod) => mod.CodeEmbed)
  ),
  table: dynamic(() => import('./Table').then((mod) => mod.Table)),
  'clinic-locator': dynamic(() =>
    import('./ClinicLocator/ClinicLocator').then((mod) => mod.ClinicLocator)
  ),
  'table-of-contents': dynamic(() =>
    import('./TableOfContents').then((mod) => mod.TableOfContents)
  ),
  'wrapper-anchor': dynamic(() =>
    import('./WrapperAnchor').then((mod) => mod.WrapperAnchor)
  ),
  events: dynamic(() => import('./Events').then((mod) => mod.Events)),
  usp: dynamic(() => import('./Usp').then((mod) => mod.Usp)),
  'success-rate': dynamic(() =>
    import('./SuccessRate').then((mod) => mod.SuccessRate)
  ),
  'clinic-hero': dynamic(() =>
    import('./Heros/ClinicHero').then((mod) => mod.ClinicHero)
  ),
  'success-rate-dynamic': dynamic(() =>
    import('./SuccessRateDynamic').then((mod) => mod.SuccessRateDynamic)
  ),
  'clinic-testimonial': dynamic(() =>
    import('./ClinicTestimonial').then((mod) => mod.ClinicTestimonial)
  ),
  'clinic-section-header': dynamic(() =>
    import('./ClinicSectionHeader').then((mod) => mod.ClinicSectionHeader)
  ),
  'new-media-carousel': dynamic(() =>
    import('./NewMediaCarousel').then((mod) => mod.NewMediaCarousel)
  ),
  'clinic-pricing-hero': dynamic(() =>
    import('./Heros/ClinicPricingHero').then((mod) => mod.ClinicPricingHero)
  ),
  'clinic-services': dynamic(() =>
    import('./ClinicServices').then((mod) => mod.ClinicServices)
  ),
  'clinic-navigation': dynamic(() =>
    import('./ClinicNavigation').then((mod) => mod.ClinicNavigation)
  ),
  'clinic-process': dynamic(() =>
    import('./ClinicProcess').then((mod) => mod.ClinicProcess)
  ),
  'team-carousel': dynamic(() =>
    import('./TeamCarousel').then((mod) => mod.TeamCarousel)
  ),
  'where-to-find-us': dynamic(() =>
    import('./WhereToFindUs').then((mod) => mod.WhreeToFindUs)
  ),
  'service-header': dynamic(() =>
    import('./ServiceHeader').then((mod) => mod.ServiceHeader)
  ),
  'clinic-blog': dynamic(() =>
    import('./ClinicBlog').then((mod) => mod.ClinicBlog)
  ),
  'link-cards-grid': dynamic(() =>
    import('./LinkCardsGrid').then((mod) => mod.LinkCardsGrid)
  ),
  'highlight-card': dynamic(() =>
    import('./HighlightCard').then((mod) => mod.HighlightCard)
  ),
  partnerships: dynamic(() =>
    import('./Partnerships').then((mod) => mod.Partnerships)
  ),
  'payment-section': dynamic(() =>
    import('./PaymentSection').then((mod) => mod.PaymentSection)
  ),
}
